import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, Redirect } from "react-router";
import { setTipoVehiculo, setError, setPrecio, setTurnosObtenidos, setNroTurno } from '../redux/actions/turnero'
import Turnos from './turnos'
import Waiting from './waiting'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Turnosval = (props) => {

    let query = useQuery();
    const nro_turno = query.get("nro_turno")

    const { temp, error, setTipoVehiculo, setError, setTurnosObtenidos, setPrecio, setNroTurno } = props

    const [cargando, setCargando] = useState(true)

    useEffect(() => {
        const obtenerDatosTurno = () => {

            document.title = "Centro Este - Rivadavia - Turnos";

            if (isNaN(nro_turno)) {
                setError("El codigo del turno debe ser de tipo numérico.")
                return
            }

            const data = {
                nro_turno_rto: parseInt(nro_turno, 10)
            }

            let url = 'https://centroeste.reviturnos.com.ar/api/auth/valTur';

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(async res => {

                    if (res.status === 200) {
                        const respuesta = await res.json();

                        if (respuesta) {
                            setNroTurno(nro_turno)
                            setCargando(false)
                            setTipoVehiculo(respuesta.tipo_vehiculo)
                            setPrecio(respuesta.precio)
                            setTurnosObtenidos(respuesta.turnos)
                        }


                    } else {
                        const error = await res.json();

                        setError(error.message)
                    }

                })
        }

        obtenerDatosTurno();

    }, [])

    return (
        <div>
            {temp.turnosObtenidos && <Turnos></Turnos>}

            { error.isThere && <Redirect to="/error" />}
            {cargando && <Waiting />}
        </div>
    )
}

const mapStateToProps = state => ({
    temp: state.turnero.temp,
    error: state.turnero.error
})

const mapDispatchToProps = {
    setTipoVehiculo: setTipoVehiculo,
    setError: setError,
    setTurnosObtenidos: setTurnosObtenidos,
    setPrecio: setPrecio,
    setNroTurno: setNroTurno
}

export default connect(mapStateToProps, mapDispatchToProps)(Turnosval)
