import React from 'react'
import Header from './header'



const Waiting = () => {



    return (
        <div style={{ position: "relative" }}>





            <Header></Header>

            <div style={{ textAlign: "center", background: "none", color: "white", border: "0", margin: "0 auto", marginTop: "60px", lineHeight: "45px" }} className="card responsive-card">

                <p>Cargando...</p>




            </div>





        </div >
    )
}

export default Waiting;
