import React from 'react'
import { BrowserRouter as Router, useLocation, Switch, Route } from 'react-router-dom'
import Turnosval from './components/turnosval'
import Finproceso from './components/finproceso'
import Generalerror from './components/error'
import Notfound from './components/notfound'
import Confirmacion from './components/confirmacion'
import 'bootstrap/dist/css/bootstrap.min.css';




const App = () => {

  document.title = "Centro Este - Rivadavia - Turnos";
  return (
    <div>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/turnos" exact component={Turnosval} />
            <Route path="/finproceso" exact component={Finproceso} />
            <Route path="/confirmado" exact component={Confirmacion} />
            <Route path="/error" exact component={Generalerror} />
            <Route component={Notfound} />
          </Switch>
        </div>
      </Router>
    </div>
  )
}

export default App;