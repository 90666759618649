import React from 'react'
import { connect } from 'react-redux'
import { setErrorConfirmOut, setHoraSeleccionada } from '../redux/actions/turnero'

const Horaseleccionada = (props) => {

    const { temp, setHoraSeleccionada, setErrorConfirmOut } = props

    const handleChangeHora = () => {
        setHoraSeleccionada("");
        setErrorConfirmOut();
    }

    return (
        <div>
            <div style={{ background: "none", color: "white", border: "0", margin: "0 auto", marginTop: "8px", height: "50px", lineHeight: "45px" }} className="card responsive-card">
                <span style={{ display: "inline", fontSize: "17px" }}>
                    <b>Hora:</b>
                    {' ' + temp.horaSeleccionada + '    '}
                    <button onClick={handleChangeHora} style={{ fontSize: "12px" }} className="btn btn-light">Cambiar</button>
                </span>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    temp: state.turnero.temp
})

const mapDispatchToProps = {
    setHoraSeleccionada: setHoraSeleccionada,
    setErrorConfirmOut: setErrorConfirmOut
}

export default connect(mapStateToProps, mapDispatchToProps)(Horaseleccionada)
