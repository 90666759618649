import React from 'react'
import Listadohorarios from './listadohorarios'

const Eleccionturno = (props) => {
    return (
        <div>
            <div style={{ background: "none", color: "white", border: "0", paddingBottom: "8px", display: "inline-block", margin: "0 auto", marginTop: "8px", lineHeight: "45px", textAlign: "center" }} className="card turnos-card">

                <p style={{ marginBottom: 0 }}>Seleccione un horario:</p>
                <div>
                    <Listadohorarios></Listadohorarios>
                </div>





            </div>
        </div>
    )
}

export default Eleccionturno;
