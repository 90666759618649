import React from 'react'

const Errormessage = ({ message }) => {



    return (
        <div>
            <div style={{ padding: "4px", fontSize: "15px", background: "red", color: "white", margin: "0 auto", marginTop: "8px", lineHeight: "20px" }} className="card responsive-card">

                {message}

            </div>
        </div>
    )
}

export default Errormessage;
