import React from 'react'
import { connect } from 'react-redux'
import { setHoraSeleccionada, setNroTurnoFinal } from '../redux/actions/turnero'

const Listadohorarios = (props) => {

    const { temp, setHoraSeleccionada, setNroTurnoFinal } = props

    const handleClickTurnoElegido = (turno) => {
        console.log(turno)
        setHoraSeleccionada(turno.hora)
        setNroTurnoFinal(turno.id)
    }

    return (
        <div>
            {
                temp.horasObtenidas.map((turno, indice) => {
                    return <button onClick={() => handleClickTurnoElegido(turno)} style={{ fontSize: "14px", width: "56px", margin: "5px", padding: "2px" }} className="btn btn-light" key={indice} value={turno.hora}>{turno.hora.substr(0, 5)}</button>
                })
            }
        </div>
    )
}

const mapStateToProps = state => ({
    temp: state.turnero.temp
})

const mapDispatchToProps = {
    setNroTurnoFinal: setNroTurnoFinal,
    setHoraSeleccionada: setHoraSeleccionada
}

export default connect(mapStateToProps, mapDispatchToProps)(Listadohorarios)
