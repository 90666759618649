import * as t from "../types";

const initialState = {
    planta: "",
    temp: {
        tipoVehiculo: "",
        tipoVehiculoEsp: -1,
        precio: "",
        turnosObtenidos: "",
        diasObtenidos: [],
        horasObtenidas: [],
        diaSeleccionado: "",
        horaSeleccionada: "",
        cargando: false
    },
    final: {
        id_turno_planta: -1,
        nro_turno: -1
    },
    mostrar: {
        tipoVehiculo: true,
        dia: false,
        hora: false,
        email: false,
        send: false
    },
    error: {
        thereIs: false,
        message: ""
    },
    errorConfirm: {
        thereIs: false,
        message: ""
    },
    urlPago: ""
}

const turnero = (state = initialState, action) => {
    switch (action.type) {

        case t.SET_TIPO_VEHICULO:
            return {
                ...state,
                temp: {
                    ...state.temp,
                    tipoVehiculo: action.payload,
                    cargando: true
                }
            };
        case t.SET_TIPO_VEHICULO_ESP:
            return {
                ...state,
                temp: {
                    ...state.temp,
                    tipoVehiculoEsp: action.payload
                }
            };
        case t.SET_PRECIO:
            return {
                ...state,
                temp: {
                    ...state.temp,
                    precio: action.payload
                }
            };
        case t.SET_URL_PAGO:
            return {
                ...state,
                urlPago: action.payload
            };
        case t.SET_NRO_TURNO:
            return {
                ...state,
                final: {
                    ...state.final,
                    nro_turno: action.payload
                }
            };
        case t.SET_NRO_TURNO_FINAL:
            return {
                ...state,
                final: {
                    ...state.final,
                    id_turno_planta: action.payload
                }
            };
        case t.ACTIVAR_CARGANDO:
            return {
                ...state,
                temp: {
                    ...state.temp,
                    cargando: true
                }
            };
        case t.DESACTIVAR_CARGANDO:
            return {
                ...state,
                temp: {
                    ...state.temp,
                    cargando: false
                }
            };
        case t.CARGAR_TURNOS:
            return {
                ...state,
                temp: {
                    ...state.temp,
                    turnosObtenidos: action.payload
                }
            };
        case t.CARGAR_DIAS:
            return {
                ...state,
                temp: {
                    ...state.temp,
                    diasObtenidos: action.payload
                }
            };
        case t.CARGAR_HORAS:
            return {
                ...state,
                temp: {
                    ...state.temp,
                    horasObtenidas: action.payload
                }
            };
        case t.SET_DIA_SELECCIONADO:
            return {
                ...state,
                temp: {
                    ...state.temp,
                    diaSeleccionado: action.payload
                }
            };
        case t.SET_HORA_SELECCIONADA:
            return {
                ...state,
                temp: {
                    ...state.temp,
                    horaSeleccionada: action.payload
                }
            };
        case t.SET_ERROR:
            return {
                ...state,
                error: {
                    isThere: true,
                    message: action.payload
                }
            };
        case t.SET_ERROR_OUT:
            return {
                ...state,
                error: {
                    isThere: false,
                    message: ""
                }
            };
        case t.SET_ERROR_CONFIRM:
            return {
                ...state,
                errorConfirm: {
                    isThere: true,
                    message: action.payload
                }
            };
        case t.SET_ERROR_CONFIRM_OUT:
            return {
                ...state,
                errorConfirm: {
                    isThere: false,
                    message: ""
                }
            };
        case t.SET_PLANTA_LH:
            return {
                ...state,
                planta: "lasheras"
            };
        case t.SET_PLANTA_MA:
            return {
                ...state,
                planta: "maipu"
            };
        // case t.ADD_JOB:
        //     return {
        //         ...state,
        //         infoPostulant: {
        //             ...state.infoPostulant,
        //             jobs: [...state.infoPostulant.jobs, action.payload]
        //         }
        //     };
        // case t.ADD_LANG:
        //     return {
        //         ...state,
        //         infoPostulant: {
        //             ...state.infoPostulant,
        //             langs: [...state.infoPostulant.langs, action.payload]
        //         }
        //     };
        // case t.SHOW_FORM_ADD_STU:
        //     return {
        //         ...state,
        //         flags: {
        //             ...state.flags,
        //             showFormAddStu: true
        //         }
        //     };
        // case t.HIDE_FORM_ADD_STU:
        //     return {
        //         ...state,
        //         flags: {
        //             ...state.flags,
        //             showFormAddStu: false
        //         }
        //     };
        // case t.SHOW_FORM_ADD_JOB:
        //     return {
        //         ...state,
        //         flags: {
        //             ...state.flags,
        //             showFormAddJob: true
        //         }
        //     };
        // case t.HIDE_FORM_ADD_JOB:
        //     return {
        //         ...state,
        //         flags: {
        //             ...state.flags,
        //             showFormAddJob: false
        //         }
        //     };
        // case t.SHOW_FORM_ADD_LANG:
        //     return {
        //         ...state,
        //         flags: {
        //             ...state.flags,
        //             showFormAddLang: true
        //         }
        //     };
        // case t.HIDE_FORM_ADD_LANG:
        //     return {
        //         ...state,
        //         flags: {
        //             ...state.flags,
        //             showFormAddLang: false
        //         }
        //     };
        default:
            return { ...state }
    }
}

export default turnero;