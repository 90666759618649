import React from 'react'
import { connect } from 'react-redux'
import { setDiaSeleccionado, setHoraSeleccionada, setErrorConfirmOut } from '../redux/actions/turnero'

const Diaseleccionado = (props) => {

    const { temp, setDiaSeleccionado, setHoraSeleccionada, setErrorConfirmOut } = props

    const handleCambiarDia = () => {
        setDiaSeleccionado("")
        setHoraSeleccionada("")
        setErrorConfirmOut()
    }

    return (
        <div>
            <div style={{ background: "none", color: "white", border: "0", margin: "0 auto", marginTop: "8px", height: "50px", lineHeight: "45px" }} className="card responsive-card">
                <span style={{ display: "inline", fontSize: "17px" }}>
                    <b>Dia:</b>
                    {' ' + temp.diaSeleccionado + '    '}
                    <button onClick={handleCambiarDia} style={{ fontSize: "12px" }} className="btn btn-light">Cambiar</button>
                </span>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    temp: state.turnero.temp
})

const mapDispatchToProps = {
    setDiaSeleccionado: setDiaSeleccionado,
    setHoraSeleccionada: setHoraSeleccionada,
    setErrorConfirmOut: setErrorConfirmOut
}

export default connect(mapStateToProps, mapDispatchToProps)(Diaseleccionado)
