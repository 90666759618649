import React from 'react'
import { connect } from 'react-redux'
import { setHorasObtenidas, setDiaSeleccionado, setHoraSeleccionada, setNroTurnoFinal } from '../redux/actions/turnero'

const Elecciondia = (props) => {

    const { temp, setHorasObtenidas, setDiaSeleccionado, setHoraSeleccionada, setNroTurnoFinal } = props

    const handleChangeDay = (e) => {
        setHoraSeleccionada("")
        setNroTurnoFinal(0)
        const horasDia = temp.turnosObtenidos.filter((reg) => reg.fecha === e.target.value);
        const horasDiaSinRepeticion = [];
        horasDia.map((horaDia, i) => {
            if (horasDiaSinRepeticion.find((reg) => reg.hora === horaDia.hora) === undefined) {
                horasDiaSinRepeticion.push(horaDia);
            }
        });
        setHorasObtenidas(horasDiaSinRepeticion)
        setDiaSeleccionado(e.target.value);



    }

    return (
        <div>
            <div style={{ background: "none", color: "white", border: "0", paddingBottom: "8px", display: "inline-block", margin: "0 auto", marginTop: "8px", lineHeight: "45px", textAlign: "center" }} className="card responsive-card">

                <p style={{ marginBottom: 0 }}>Seleccione un dia</p>


                <select onChange={handleChangeDay} style={{ width: "200px", margin: "0 auto" }} className="form-control" id="exampleFormControlSelect1">
                    <option value="">Desplegar</option>
                    {temp.diasObtenidos.map((dia, indice) => {
                        return <option key={indice} >{dia}</option>
                    })}
                </select>



            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    temp: state.turnero.temp
})

const mapDispatchToProps = {
    setHorasObtenidas: setHorasObtenidas,
    setDiaSeleccionado: setDiaSeleccionado,
    setHoraSeleccionada: setHoraSeleccionada,
    setNroTurnoFinal: setNroTurnoFinal
}

export default connect(mapStateToProps, mapDispatchToProps)(Elecciondia)
