import React, { useState, useEffect } from 'react'
import Header from './header'


const Confirmacion = () => {





    return (
        <div style={{ position: "relative" }}>





            <Header></Header>

            <div style={{ textAlign: "center", background: "none", color: "white", border: "0", margin: "0 auto", marginTop: "60px", lineHeight: "45px" }} className="card responsive-card">

                <p>El turno ha sido confirmado.</p>
                {/* <p>Le hemos enviado los detalles el mismo a su correo electrónico.</p> */}
                <p>¡Muchas gracias!</p>



            </div>





        </div >
    )
}




export default Confirmacion;
