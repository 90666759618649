import * as t from "../types"


export const setTipoVehiculo = (tipoVehiculo) => ({
    type: t.SET_TIPO_VEHICULO,
    payload: tipoVehiculo
})

export const setTipoVehiculoEsp = (tipoVehiculo) => ({
    type: t.SET_TIPO_VEHICULO_ESP,
    payload: tipoVehiculo
})

export const setPrecio = (precio) => ({
    type: t.SET_PRECIO,
    payload: precio
})

export const setUrlPago = (url) => ({
    type: t.SET_URL_PAGO,
    payload: url
})

export const setNroTurno = (nroTurno) => ({
    type: t.SET_NRO_TURNO,
    payload: nroTurno
})

export const activarCargando = () => ({
    type: t.ACTIVAR_CARGANDO
})

export const desactivarCargando = () => ({
    type: t.DESACTIVAR_CARGANDO
})

export const setTurnosObtenidos = (turnos) => ({
    type: t.CARGAR_TURNOS,
    payload: turnos
})

export const setDiasObtenidos = (dias) => ({
    type: t.CARGAR_DIAS,
    payload: dias
})

export const setHorasObtenidas = (horas) => ({
    type: t.CARGAR_HORAS,
    payload: horas
})

export const setDiaSeleccionado = (dia) => ({
    type: t.SET_DIA_SELECCIONADO,
    payload: dia
})

export const setHoraSeleccionada = (hora) => ({
    type: t.SET_HORA_SELECCIONADA,
    payload: hora
})

export const setNroTurnoFinal = (id_turno) => ({
    type: t.SET_NRO_TURNO_FINAL,
    payload: id_turno
})

export const setError = (mensaje) => ({
    type: t.SET_ERROR,
    payload: mensaje
})

export const setErrorConfirm = (mensaje) => ({
    type: t.SET_ERROR_CONFIRM,
    payload: mensaje
})

export const setErrorOut = () => ({
    type: t.SET_ERROR_OUT
})

export const setErrorConfirmOut = () => ({
    type: t.SET_ERROR_CONFIRM_OUT
})

export const setPlantaLh = () => ({
    type: t.SET_PLANTA_LH
})

export const setPlantaMa = () => ({
    type: t.SET_PLANTA_MA
})