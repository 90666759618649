import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setUrlPago, setErrorConfirm, setErrorConfirmOut } from '../redux/actions/turnero'
import { Redirect } from 'react-router'
import Errormessage from './errormessage'




const Ingresoemail = (props) => {

    const { urlPago, planta, temp, final, errorConfirm, setErrorConfirm, setErrorConfirmOut, setUrlPago } = props

    useEffect(() => {

        if (urlPago) {
            const url = "/finproceso"
            setRedireccionar(url)
        }


    }, [urlPago])



    const [email, setEmail] = useState("")
    const [dominio, setDominio] = useState("")
    const [nombre, setNombre] = useState("")
    const [showSend, setShowSend] = useState(false)
    const [cargando, setCargando] = useState(false)
    const [flagError, setFlagError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [redireccionar, setRedireccionar] = useState("")

    const handleChangeEmail = (e) => {
        setErrorConfirmOut();
        var result = /^[-\w.%+]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,125}[a-zA-Z]{2,63}$/.test(e.target.value);
        if (result) {
            setEmail(e.target.value)
            setShowSend(true)
        } else {
            setEmail(e.target.value)
            setShowSend(false)
        }

    }


    const handleConfirmarTurno = async () => {


        setErrorConfirmOut();
        setCargando(true)
        const data = {
            id_turno: final.id_turno_planta,
            email,
            origen: 'T',
            nro_turno_rto: final.nro_turno,
            tipo_vehiculo: temp.tipoVehiculo
        }
        console.log(data);

        let url = 'https://centroeste.reviturnos.com.ar/api/auth/solTur'


        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(async res => {

                if (res.status === 200) {
                    const respuesta = await res.json();

                    if (respuesta) {
                        setUrlPago(respuesta.url_pago)
                    }


                } else {
                    const error = await res.json();
                    setCargando(false)
                    setErrorConfirm(error.mensaje)

                }

            })


    }

    return (
        <div>
            <div style={{ background: "none", color: "white", border: "0", paddingBottom: "8px", display: "inline-block", margin: "0 auto", marginTop: "8px", lineHeight: "45px", textAlign: "center" }} className="card responsive-card">

                <p style={{ lineHeight: "20px", marginTop: "8px" }}> Para verificar su identidad y solicitar el turno, ingrese los siguientes datos:</p>
                <input placeholder="Correo electronico" onChange={handleChangeEmail} style={{ margin: "0 auto" }} type="email" value={email} className="form-control inpemail" />
                <div style={{ width: "100%", height: "8px" }}></div>

                {(showSend && !cargando) && <>
                    <br />
                    <button onClick={handleConfirmarTurno} className="btn btn-light">Confirmar turno</button>
                </>
                }
            </div>



            {cargando && <><br /><br /><div style={{ marginTop: "8px" }} className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div></>
            }

            {
                errorConfirm.isThere && <Errormessage message={errorConfirm.message}></Errormessage>
            }

            {redireccionar && <Redirect to={redireccionar} />}

        </div>
    )
}

const mapStateToProps = state => ({
    temp: state.turnero.temp,
    final: state.turnero.final,
    planta: state.turnero.planta,
    urlPago: state.turnero.urlPago,
    errorConfirm: state.turnero.errorConfirm
})

const mapDispatchToProps = {
    setErrorConfirm: setErrorConfirm,
    setErrorConfirmOut: setErrorConfirmOut,
    setUrlPago: setUrlPago
}

export default connect(mapStateToProps, mapDispatchToProps)(Ingresoemail)
