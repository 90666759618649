export const SET_TIPO_VEHICULO = "SET_TIPO_VEHICULO";
export const SET_TIPO_VEHICULO_ESP = "SET_TIPO_VEHICULO_ESP";
export const SET_PRECIO = "SET_PRECIO";
export const SET_URL_PAGO = "SET_URL_PAGO";
export const SET_NRO_TURNO = "SET_NRO_TURNO";
export const ACTIVAR_CARGANDO = "ACTIVAR_CARGANDO";
export const DESACTIVAR_CARGANDO = "DESACTIVAR_CARGANDO";
export const CARGAR_TURNOS = "CARGAR_TURNOS";
export const CARGAR_DIAS = "CARGAR_DIAS";
export const CARGAR_HORAS = "CARGAR_HORAS";
export const SET_DIA_SELECCIONADO = "SET_DIA_SELECCIONADO";
export const SET_HORA_SELECCIONADA = "SET_HORA_SELECCIONADA";
export const SET_NRO_TURNO_FINAL = "SET_NRO_TURNO_FINAL";
export const SET_ERROR = "SET_ERROR";
export const SET_ERROR_OUT = "SET_ERROR_OUT";
export const SET_ERROR_CONFIRM = "SET_ERROR_CONFIRM";
export const SET_ERROR_CONFIRM_OUT = "SET_ERROR_CONFIRM_OUT";
export const SET_PLANTA_LH = "SET_PLANTA_LH";
export const SET_PLANTA_MA = "SET_PLANTA_MA";
